@charset "utf-8";

$defaultcolor       : #02c39a;
$defaultcolor_dark  : #028090;
$colors: (
  // No tag
  ""                : $defaultcolor,

  // Blue
  "php"             : #7A86B8,
  "css"             : #29A8DF,
  "sql"             : #397084,
  "drupal"          : #008BCA,
  "vagrant"         : #3f7cf5,
  "vscode"          : #0385d2,
  "python"          : #3776ab,
  "docker"          : #019cdf,

  // Green
  "prestashop"      : #71BF45,
  "chrome"          : #4EB447,
  "nginx"           : #239D07,
  "mathematiques"   : #22694a,

  // Yellow
  "js"              : #FEDA3E,
  "sysadmin"        : #D1DB1C,
  "devops"          : #D1DB1C,

  // Orange
  "sublime-text"    : #FF8100,
  "html"            : #F16528,
  "svg"             : #DE8500,
  "aws"             : #ec912c,
  "cdk"             : #ec912c,

  // Red
  "git"             : #F05133,
  "apache"          : #EF0031,
  "ruby"            : #CC342D,

  // Pink
  "sass"            : #CC6698,
  "macos"           : #DA6F59,

  // Purple
  "osint"           : #782b74,

  // Default
  "divers"          : $defaultcolor,
  "regex"           : $defaultcolor,
  "software"        : $defaultcolor,
  "shell"           : $defaultcolor
);

// Optimized to minimize the CSS generated
@each $tag, $color in $colors {
  // ::-moz- needs to be apart, or the whole selector will be ignored
  .c-#{$tag} ::-moz-selection {
    color: $color;
  }
  .c-#{$tag} ::selection,
  .c-#{$tag} p a:hover,
  .c-#{$tag} li a:hover,
  .c-#{$tag} #disqus_thread a:hover,
  .c-#{$tag} #partager-block > a:hover::before,
  .c-#{$tag} #partager-block > a:hover::after,
  section.articles article.c-#{$tag} h2 a,
  section.tags > a.c-#{$tag}:hover,
  section.tags.c-#{$tag} > a:hover,
  section.tags.c-#{$tag} h2 a {
    color: $color;
  }
  .c-#{$tag} #partager-block > a:hover {
    background: $color;
  }
  .c-#{$tag} #canvas rect {
    fill: $color;
  }
}

$white              : #fff;
$grey               : #bbb;
$grey_light         : #eee;
$grey_medium        : #666;
$grey_dark          : #333;

$logo_height        : 180px;
$header_height      : 250px;
$content_width      : (
  mobile:    100%,
  tablet:    500px,
  desktop:   800px
);
$body_padding       : 30px;
$content_padding    : 50px;
$section_padding    : 40px;
$tag_padding        : 30px;
$tag_height         : 40px;
$tag_width          : 100px;
$font_size          : 22px;

$transition_header  : .2s;

@font-face {
  font-family: "Source Sans Pro ExtraLight";
  src: url('../fonts/SourceSansPro-ExtraLight.eot');
  src: url('../fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
       url('../fonts/SourceSansPro-ExtraLight.woff') format('woff'),
       url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
       url('../fonts/SourceSansPro-ExtraLight.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Code Pro Light";
  src: url('../fonts/sourcecodepro-light-webfont.eot');
  src: url('../fonts/sourcecodepro-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/sourcecodepro-light-webfont.woff') format('woff'),
       url('../fonts/sourcecodepro-light-webfont.ttf') format('truetype'),
       url('../fonts/sourcecodepro-light-webfont.svg#source_code_prolight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
%font-light {
  font-family: "Source Sans Pro ExtraLight", "Trebuchet MS", sans-serif;
}
%font-regular {
  font-family: "Source Sans Pro ExtraLight", "Trebuchet MS", sans-serif;
  font-weight: 900;
}
%font-code {
  font-family: "Source Code Pro Light", "Consolas", "Monaco", "Lucida Console", "Liberation Mono", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace;
}
