@charset "utf-8";

body > footer {
  width: 100%;
  background: var(--bg-color);
  padding-bottom: 80px;

  @at-root #partager-block {
    width: 100%;
    margin: 0 auto;
    padding: 0 $content_padding;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 120px;
    color: var(--bg-color);

    @include media($from: tablet) {
      width: map-get($content_width, tablet);
    }
    @include media($from: desktop) {
      padding: 0;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      width: map-get($content_width, desktop);
    }

    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: var(--fg-color);
      z-index: 1;
    }
    h2 {
      display: none;
      margin-right: auto;
      top: -8px;
      z-index: 2;

      &:before {
        content: none;
      }
      @include media($from: desktop) {
        display: block;
      }
    }
    > a {
      background: var(--fg-color);
      color: var(--bg-color);
      text-decoration: none;
      float: left;
      font-size: 120%;
      margin-right: 25px;
      padding: 0 30px;
      text-align: center;
      position: relative;
      -webkit-transition: all .4s;
      transition: all .4s;
      z-index: 2;

      @include darkmode() {
        img {
          filter: brightness(0.2);
        }
      }

      &:before {
        border-left: 20px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 120px solid transparent;
        content: "";
        left: -20px;
        position: absolute;
        top: 0;
        -webkit-transition: all .4s;
        transition: all .4s;
      }
      &:after {
        border-left: 0px solid transparent;
        border-right: 20px solid transparent;
        border-top: 120px solid transparent;
        content: "";
        position: absolute;
        right: -20px;
        top: 0;
        width: 0;
        -webkit-transition: all .4s;
        transition: all .4s;
      }
      &:hover {
        color: var(--fg-color);

        &:before,
        &:after {
          border-top-color: currentColor;
          border-bottom-color: currentColor;
        }
      }
    }
  }

  @at-root #commentaires-block {
    width: 100%;
    margin: $section_padding auto 0;
    padding: 0 $content_padding;

    @include media($from: tablet) {
      padding: 0;
      width: map-get($content_width, tablet);
    }
    @include media($from: desktop) {
      width: map-get($content_width, desktop);
    }

    h2 {
      color: var(--fg-color);
      padding-top: ($section_padding / 2);
      margin-bottom: $section_padding;

      &:before {
        content: none;
      }
    }

    @at-root #disqus_thread {
      color: var(--fg-color);
    }
  }
}
