/**
* SassQueries
* Manage media-queries in Sass easily.
*
* @author   Samuel Marchal (zessx)
* @version  0.3
*/

$sq-breakpoints: (
  mobile:  320px,
  tablet:  768px,
  desktop:   992px
) !default;
$sq-media: "all" !default;
$sq-debug: false !default;
$sq-mobile-first: true !default;

@mixin media($from: false, $until: false, $and: false, $media: $sq-media, $mobile-first: $sq-mobile-first)
{
  $min: 0;
  $max: 0;
  $query: "";

  @if $from {
    @if type-of($from) == number {
      $min: $from;
    } @else {
      $min: map-get($sq-breakpoints, $from);
    }
  }
  @if $until {
    @if type-of($until) == number {
      $max: $until - 1;
    } @else {
      $max: map-get($sq-breakpoints, $until) - 1;
    }
  }

  $dir-min: "max";
  $dir-max: "min";
  @if $mobile-first {
    $dir-min: "min";
    $dir-max: "max";
  }

  @if $min != 0 { $query: "#{$query} and (#{$dir-min}-width: #{$min})"; }
  @if $max != 0 { $query: "#{$query} and (#{$dir-max}-width: #{$max})"; }
  @if $and    { $query: "#{$query} and #{$and}"; }

  @media #{$media} #{$query} {
    @content;
  }
}

@mixin darkmode()
{
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@if $sq-debug {
  body:before {
    display: block;
    position: fixed;
    bottom: -1px;
    right: -1px;
    padding: 3px;
    font-family: Verdana;
    font-size: 10px;
    pointer-events: none;
    border: 1px solid #333;
    color: #333;
    background: rgba(255, 255, 255, .6);
    z-index: 99999;

    @if $sq-mobile-first {
      $min-breakpoint: min(map-values($sq-breakpoints)...);
      @each $label, $width in $sq-breakpoints {
        @include media($from: $label) {
          content: ">= #{$width} (#{$label})";
        }
      }
      @include media($until: $min-breakpoint) {
        content: "< #{$min-breakpoint} (undefined)";
      }
    } @else {
      $max-breakpoint: max(map-values($sq-breakpoints)...);
      @each $label, $width in $sq-breakpoints {
        @include media($from: $label) {
          content: "<= #{$width} (#{$label})";
        }
      }
      @include media($until: $max-breakpoint) {
        content: "> #{$max-breakpoint} (undefined)";
      }
    }
  }
}